import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";

const FAQAccordion = ({ language }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question:
        language === "english"
          ? "How much does a psycho-social evaluation cost?"
          : "¿Cuales son los precios para una evaluación?",
      answer:
        language === "english"
          ? `Please contact me at marissa@mosaiccounselingcollective.com for more information on fees. I am happy to offer payment plans to all clients and do my part to make services as accessible as possible to individuals. I do hold a limited number of sliding scale evaluations to those who may qualify for these services. It should also be noted that psycho-social evaluations are not covered by insurance as they are not a medical document or service.`
          : `Por favor contactame en marissa@mosaiccounselingcollective.com para más información sobre los honorarios. Estoy feliz de ofrecer planes de pago a todos los clientes y hago mi parte para que los servicios sean lo más accesibles posible para las personas. Tengo un número limitado de evaluaciones a precio reducido para aquellos que puedan calificar para estos servicios. También debe tenerse en cuenta que las evaluaciones psicosociales no están cubiertas por el seguro ya que no son un documento o servicio médico. Para más información sobre los honorarios, por favor contactame como marissa@mosaiccounselingcollective.com. Podría ofrecer un plan de pago a todos los clientes y me gustaría que todas las personas tuvieran acceso a los servicios. Tengo algunos espacios para evaluaciones con descuento para la gente que pueda calificar para este tipo de servicios. Se debe anotar que las evaluaciones no están cubiertas por seguros porque no es un servicio médico.`,
    },
    {
      question:
        language === "english"
          ? "What is a psycho-social evaluation and what is included?"
          : "¿Que es una evaluación psico-social y que incluye?",
      answer:
        language === "english"
          ? `A psycho-social evaluation is a comprehensive look into your background, trauma, overall health and well-being and your current situation with how it relates to your immigration case. Throughout the evaluation, you can expect: 1. One to two 90-180 minute interview appointments with a single primary client. 2. Follow-up telephone communication regarding any additional details that need to be verified. 3. Collateral Communication with the attorney/paralegal on the case. 4. A comprehensive 15-20 page report.`
          : `Una evaluación psicosocial es una mirada integral a su historial, trauma, salud y bienestar en general y su situación actual en relación con su caso de inmigración. A lo largo de la evaluación, puede esperar: 1. Una o dos citas de entrevista de 90-180 minutos con un solo cliente principal. 2. Comunicación telefónica de seguimiento con respecto a cualquier detalle adicional que deba verificarse. 3. Comunicación colateral con el abogado/paralegal en el caso. 4. Un informe exhaustivo de 15-20 páginas. Una evaluación es un informe extenso de su historia, trauma, salud y bienestar con su situación actual relacionada a su caso de inmigracion legal. Durante el proceso de la evaluación se podría esperar: 1. Una o dos entrevistas que pueden durar entre 90-120 minutos con un cliente. 2. Comunicación por teléfono para verificar algunos detalles para su informe que nos hayan faltado. 3. Habrá comunicación colateral con el abogado/asistente legal sobre el caso. 4. Habrá un informe extenso entre 15-30 páginas.`,
    },
    {
      question:
        language === "english"
          ? "What do I need to bring to my session?"
          : "¿Qué debo tener en mi sesion virtual?",
      answer:
        language === "english"
          ? `You will need to be alone during the evaluation. However, please bring: A copy of your photo ID. I know that these topics can be difficult to discuss, please bring anything that can help bring you comfort (a blanket, a comforting beverage or snack, a candle, pets, etc.)`
          : `Necesitarás estar solo durante la evaluación. Sin embargo, por favor trae: Una copia de tu identificación con foto. Sé que estos temas pueden ser difíciles de discutir, por favor trae cualquier cosa que pueda ayudarte a sentirte cómodo (una manta, una bebida o refrigerio reconfortante, una vela, mascotas, etc.)./ 1. Tiene que estar solo para la cita. 2. Una copia de un tipo de identificación con una foto. 3. Algo que podría ofrecer comodidad o para sentirse bien durante la cita (Una colcha, una bebida, comida, una vela, mascota, etc.). Yo sé que estos tópicos pueden ser muy difíciles de recordar.`,
    },
    {
      question:
        language === "english"
          ? "Where are you located?"
          : "¿Donde esta ubicada?",
      answer:
        language === "english"
          ? "I am physically located in Washington state, but am currently only offering virtual services throughout Washington, Oregon and Colorado."
          : "Estoy físicamente ubicado en el estado de Washington, pero actualmente solo ofrezco servicios virtuales en Washington, Oregón y Colorado. / Manera virtual en Washington, Oregon y Colorado.",
    },
  ];

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-accordion">
      <h3>
        {language === "english"
          ? "Frequently Asked Questions"
          : "Preguntas Frecuentes"}
      </h3>
      <div className="faq-top-bar"></div>
      {faqs.map((faq, index) => (
        <div key={index}>
          <div className="faq-question" onClick={() => toggleFAQ(index)}>
            {faq.question}
            <FaPlus
              className={`faq-icon ${activeIndex === index ? "active" : ""}`}
            />
          </div>
          <div className={`faq-answer ${activeIndex === index ? "show" : ""}`}>
            {faq.answer}
          </div>
          <div className="faq-bottom-bar"></div>
        </div>
      ))}
    </div>
  );
};

export default FAQAccordion;
